import styled, { css } from "styled-components";

interface TagProps {
	variant?: string;
}

export const StyledTag = styled.li<TagProps>`
	color: ${({ theme }) => theme.colors.text.primary};
	background: ${({ theme }) => theme.colors.primary};
	border-radius: 8px;
	padding: 0.35rem 0.75rem;
	font-size: 0.8rem;
	transition: all 0.25s;

	&:hover {
		background: black;
		font-size: 0.85rem;
		color: ${({ theme }) => theme.colors.accent};
	}

	${({ variant }) => {
		switch (variant) {
			case "invert":
				return css`
					&:hover {
						background: inherit;
						font-size: 0.8rem;
					}
				`;
			default:
				return;
		}
	}}
`;
