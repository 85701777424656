import styled from "styled-components";

export const StyledProjectsList = styled.section`
	background: ${({ theme }) => theme.colors.primary};

	& > div {
		width: min(80%, 1200px);
		margin-inline: auto;
		/* @media screen and (max-width: 1200px) {
			width: 90%;
		} */
	}

	div.projects-container {
		width: 100%;
		display: flex;
		gap: 5rem;
		flex-direction: column;
		place-items: center;

		/* @media screen and (max-width: 1200px) {
			flex-direction: row;
			gap: 3rem;
			justify-content: space-between;
		} */

		@media screen and (max-width: 1200px) {
			flex-direction: column;
		}
	}
`;
