// my-theme.ts
import { DefaultTheme } from "styled-components";

const myTheme: DefaultTheme = {
	colors: {
		primary: "#17141d",
		secondary: "#14213D",
		accent: "#FCA212",
		text: {
			primary: "#C4C4C4",
		},
	},
	fonts: {
		primary: `"Fira Sans", sans-serif`,
		secondary: `'Roboto Mono', monospace`,
	},
};

export { myTheme };
