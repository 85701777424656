import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Button } from "./styles/Button.styled";
import { StyledCard } from "./styles/Project.styled";
import { StyledTag } from "./styles/Tag.styled";

interface ProjectProps {
	project: {
		uuid: number;
		image: string;
		name: string;
		description: string;
		skills: string[];
		github: string;
		demo: string;
		blog_post: string;
		isFeatured: boolean;
	};
}

const Project: FC<ProjectProps> = ({ project }) => (
	<StyledCard>
		<div>
			<p className="accented">
				{project.isFeatured && "Featured Project"}
			</p>
			<header>
				<h4>{project.name}</h4>
			</header>
			<p>{project.description}</p>
			<ul>
				{project.skills.map((skill, index) => (
					<StyledTag key={index} variant="invert">
						{skill}
					</StyledTag>
					// <li key={index}>{skill}</li>
				))}
			</ul>

			<footer>
				<div id="btn-large">
					{project.github && (
						<a
							href={project.github}
							target="_blank"
							rel="noreferrer noopener">
							<Button variant="secondary">
								<span>View Code</span>{" "}
								<FontAwesomeIcon icon={faGithub} size="lg" />
							</Button>
						</a>
					)}
					{project.demo && (
						<a
							href={project.demo}
							target="_blank"
							rel="noreferrer noopener">
							<Button variant="primary">
								<span>Demo</span>{" "}
								<FontAwesomeIcon
									icon={faArrowUpRightFromSquare}
									size="lg"
								/>
							</Button>
						</a>
					)}
					{project.blog_post && (
						<Button variant="tertiary">Learn More</Button>
					)}
				</div>

				<div id="btn-small">
					{project.github && (
						<a
							href={project.github}
							target="_blank"
							rel="noreferrer noopener">
							<FontAwesomeIcon icon={faGithub} size="2x" />
						</a>
					)}
					{project.github && (
						<a
							href={project.demo}
							target="_blank"
							rel="noreferrer noopener">
							<FontAwesomeIcon
								icon={faArrowUpRightFromSquare}
								size="2x"
							/>
						</a>
					)}

					{project.blog_post && (
						<Button variant="tertiary">Learn More</Button>
					)}
				</div>
			</footer>
		</div>
		<figure>
			<img src={`./${project.image}`} alt="Snapshot of Project Render" />
		</figure>
	</StyledCard>
);

export default Project;
