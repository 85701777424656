import { FC } from "react";
import { Button } from "./styles/Button.styled";
import { StyledHeader, StyledPhoneHeader } from "./styles/Header.styled";
import { ReactComponent as Logo } from "./images/logo.svg";

import {
	faHouse,
	faUser,
	faPencil,
	faComment,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header: FC<any> = () => (
	<>
		<StyledHeader>
			<div>
				<Logo />
			</div>

			<nav>
				<ul>
					<li>
						<a href="#home">
							<FontAwesomeIcon icon={faHouse} size="lg" /> home
						</a>
					</li>
					<li>
						<a href="#about">
							<FontAwesomeIcon icon={faUser} size="lg" /> about
						</a>
					</li>
					<li>
						<a href="#projects">
							<FontAwesomeIcon icon={faPencil} size="lg" />{" "}
							projects
						</a>
					</li>
					<li>
						<a href="#contact">
							<FontAwesomeIcon icon={faComment} size="lg" />{" "}
							contact
						</a>
					</li>
					<li>
						<Button
							variant="secondary"
							onClick={() =>
								window.open(
									`${process.env.PUBLIC_URL}/resume.pdf`,
									"_blank"
								)
							}>
							resume
						</Button>
					</li>
				</ul>
			</nav>
		</StyledHeader>

		<StyledPhoneHeader>
			<nav>
				<ul>
					<li>
						<a href="#home">
							<FontAwesomeIcon icon={faHouse} size="lg" />
						</a>
					</li>
					{/* <li className="spacing">|</li> */}
					<li>
						<a href="#about">
							<FontAwesomeIcon icon={faUser} size="lg" />
						</a>
					</li>

					{/* <li className="spacing">|</li> */}
					<li>
						<a href="#projects">
							<FontAwesomeIcon icon={faPencil} size="lg" />
						</a>
					</li>
					{/* <li className="spacing">|</li> */}
					<li>
						<a href="#contact">
							<FontAwesomeIcon icon={faComment} size="lg" />
						</a>
					</li>
				</ul>
			</nav>
		</StyledPhoneHeader>
	</>
);

export default Header;
