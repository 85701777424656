import { FC } from "react";
import { StyledFooter } from "./styles/Footer.styled";

const Footer: FC<any> = () => (
	<StyledFooter>
		<p>
			Designed & Developed by{" "}
			<span className="accented">Abrahym Sharf Eldden</span>
		</p>
	</StyledFooter>
);

export default Footer;
