import styled from "styled-components";

export const StyledHeader = styled.header`
	width: 100%;
	color: white;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 2rem;

	font-family: ${({ theme }) => theme.fonts.secondary};

	position: fixed;
	top: 0;
	background-color: ${({ theme }) => theme.colors.primary + "fa"};
	z-index: 99;

	div {
		width: 48px;
		height: 48px;
	}
	nav ul {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		list-style: none;
		margin: 0;

		li {
			a {
				transition: all 0.2s ease-in-out;
			}
			a:hover {
				color: ${({ theme }) => theme.colors.accent};
			}
		}
	}

	@media screen and (max-width: 800px) {
		padding-inline-end: 0;
		padding-block-end: 0;
		position: absolute;

		background-color: transparent;
		nav {
			display: none;
		}
	}
`;

export const StyledPhoneHeader = styled.header`
	display: none;

	@media screen and (max-width: 800px) {
		display: block;
	}

	position: fixed;
	bottom: -5px;
	background: green;
	height: 80px;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.primary};
	box-shadow: 0 0 32px -16px black;
	z-index: 99;
	nav {
		width: 100%;
		height: 100%;

		ul {
			width: 100%;
			height: 100%;
			padding: 0;
			margin: 0;
			display: flex;
			justify-content: space-around;
			align-items: center;
			padding-block-end: 5px;

			li {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;

				transition: all 0.25s ease-in-out;

				a {
					display: flex;

					align-items: center;
					justify-content: center;
					width: 100%;
					height: 100%;
				}
				&.spacing {
					opacity: 0.05;
					font-size: 2rem;
				}

				/* &:hover, */
				&:active {
					background: ${({ theme }) => theme.colors.accent};
					color: ${({ theme }) => theme.colors.primary};
				}
			}
		}
	}
`;
