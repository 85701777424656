import styled, { css } from "styled-components";

interface ButtonProps {
	variant: string;
}

export const Button = styled.button<ButtonProps>`
	width: fit-content;
	padding: 1rem 2rem;
	border-radius: 0.75rem;
	font-family: ${({ theme }) => theme.fonts.secondary};
	font-size: 0.9em;
	font-weight: 800;

	border-width: 2px;
	border-style: solid;

	${({ variant }) => {
		switch (variant) {
			case "primary":
				return css`
					background: ${({ theme }) => theme.colors.accent};
					border-color: ${({ theme }) => theme.colors.primary};
					color: ${({ theme }) => theme.colors.primary};
				`;
			case "secondary":
				return css`
					background: transparent;
					border-color: ${({ theme }) => theme.colors.accent};
					color: ${({ theme }) => theme.colors.accent};
				`;
			case "tertiary":
				return css`
					background: transparent;
					color: ${({ theme }) => theme.colors.accent};
					border: none;
					text-decoration: underline;
				`;
			default:
				return;
		}
	}}

	@media screen and (max-width: 550px) {
		/* padding: 0.5rem 2rem; */

		span {
			display: none;
		}
	}
`;
