import { FC } from "react";
import { StyledAbout } from "./styles/About.styled";
import { StyledTag } from "./styles/Tag.styled";
import Abrahym from "./images/Abrahym.jpg";

const listOfSkills: string[] = [
	"JavaScript (ES6+)",
	"typescript",
	"React",
	"Express",
	"Rest api",
	"SQL",
	"PostgreSQL",
	"mySQL",
	"Sequelize",
	"styled-components",
	"Bootstrap",
	"Sass",
	"Scss",
	"Git",
	"Nginx",
	"Node",
	"UI Design",
	"User Experience",
	"Adobe XD",
	"Figma",
];

const About: FC<any> = () => (
	<StyledAbout id="about">
		<div>
			<h3>Some Things About Me</h3>
			<section>
				<figure>
					<img src={Abrahym} alt="Abrahym Sharf Eldden" />
				</figure>
				<p>
					Hey! I'm Abrahym and I enjoy sitting for hours and hours on
					end trying to get ideas translated to web-speak. My
					programming interest stems all the way back to around 2014
					where I would sometimes help a friend debug and problem
					solve some of his scripts for a game we played.
					<br />
					<br />
					I made the jump to Web Development in 2016 where I learnt
					basic web development concepts at a non-profit, city-funded,
					organization before going to college to study Computer
					Science.
					<br />
					<br />
					When I'm not coding, I'm usually cooking, thinking about
					cooking, eating, or thinking about eating
				</p>
			</section>
			<section>
				<h4>My Skills</h4>
				<p>
					Since starting Web Development, I've gained a solid
					understanding of the importance of user experience and
					reusability and scalability in applications.
				</p>
				<ul>
					{listOfSkills.map((skill, index) => (
						<StyledTag key={index}>{skill.toLowerCase()}</StyledTag>
					))}
				</ul>
			</section>
		</div>
	</StyledAbout>
);

export default About;
