import { ThemeProvider } from "styled-components";

import { myTheme as theme } from "./components/styles/my-theme";
import GlobalStyles from "./components/styles/Global";

import About from "./components/About";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Home from "./components/Home";
import Projects from "./components/Projects";
import Footer from "./components/Footer";

function App() {
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyles />
			<Header />
			<Home />
			<About />
			<Projects />
			<Contact />
			<Footer />
		</ThemeProvider>
	);
}

export default App;
