import styled from "styled-components";

export const StyledFooter = styled.footer`
	padding-block: 1rem;
	background-color: ${({ theme }) => theme.colors.primary};

	p {
		text-align: center;
		font-size: 0.85rem;
		color: ${({ theme }) => theme.colors.text.primary};
		font-family: ${({ theme }) => theme.fonts.secondary};
		transition: 0.3s ease-in;
		opacity: 0.75;

		&:hover {
			font-weight: 500;
			opacity: 1;
		}
	}

	@media screen and (max-width: 800px) {
		margin-block-end: 75px;
	}
`;
