import styled from "styled-components";

export const StyledCard = styled.article`
	height: 360px;
	display: flex;
	flex-direction: row;

	/* flex: 0 0 100%; */

	div {
		height: 100%;
		padding-block: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		padding-inline-end: 3rem;

		p {
			opacity: 85%;
			padding-block-end: 1rem;
			padding-inline-end: 1rem;
			height: 120px;
			overflow: hidden;

			&.accented {
				height: 50px;
				padding-block: 1rem 0.5rem;
				opacity: 0.65;
			}
		}

		ul {
			font-family: ${({ theme }) => theme.fonts.secondary};
			font-size: 0.85rem;
			flex-wrap: wrap;

			li {
				padding-inline: 0;
			}
		}

		footer {
			margin-top: 2rem;
			display: flex;
			gap: 2rem;

			div {
				flex-direction: row;
				gap: 2rem;
			}

			#btn-small {
				display: none;
			}

			@media screen and (max-width: 1200px) {
				#btn-large {
					display: none;
				}
				#btn-small {
					opacity: 0.85;
					padding: 0;
					width: 100%;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					gap: 3rem;
					align-items: center;

					a {
						color: ${({ theme }) => theme.colors.text.primary};
						transition: 0.1s ease-out;

						&:hover,
						&:active {
							color: ${({ theme }) => theme.colors.accent};
						}
					}

					button {
						padding: 0;
						margin: 0;
						width: fit-content;
					}
				}
			}
		}
	}

	figure {
		height: 360px;
		aspect-ratio: 16 / 10;
	}

	/* Breakpoint for this component is 1200px */

	@media (max-width: 1200px) {
		flex-direction: column-reverse;
		width: 50%;
		height: auto;

		& > div {
			width: 100%;
			padding: 0;
			justify-content: flex-start;

			footer {
				margin-top: 2rem;
				display: flex;
				flex-direction: row;
				gap: 2rem;

				button {
					width: 100%;
					margin-inline: auto;
				}
			}
		}

		figure {
			width: 100%;
			height: calc(100% * 10 / 16);
		}

		/* 
			* aspect-ratio does not work in Safari ~15
			* Must use -webkit-hyphens support to target
			* only Safari 9+	
		*/
		@supports (-webkit-hyphens: none) {
			figure {
				width: 90vw;
				height: calc(calc(90vw * 10) / 16);
			}
		}
	}

	@media screen and (max-width: 800px) {
		width: 100%;
		margin-inline: auto;

		div p {
			height: auto;
		}
	}
`;
