import { FC } from "react";
import { StyledProjectsList } from "./styles/Projects.styled";
import Project from "./Project";

import projectsList from "./projects.json";

const Projects: FC<any> = () => {
	return (
		<StyledProjectsList id="projects">
			<div>
				<h3>Some Things I've Worked On</h3>
				<div className="projects-container">
					{projectsList.map(project => (
						<Project key={project.uuid} project={project} />
					))}
				</div>
			</div>
		</StyledProjectsList>
	);
};

export default Projects;
