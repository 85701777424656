import axios from "axios";
import { FC, useState } from "react";
import { Button } from "./styles/Button.styled";
import { StyledContact } from "./styles/Contact.styled";

const Contact: FC<any> = () => {
	// const [name, setName] = useState();
	// const [email, setEmail] = useState();
	// const [subject, setSubject] = useState();
	// const [message, setMessage] = useState();

	const [state, setState] = useState({
		name: "",
		email: "",
		subject: "",
		message: "",
	});

	const handleChange = (event: any) => {
		setState({
			...state,
			[event.target.name]: event.target.value,
		});
	};

	const handleSubmit = async (event: any) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();

		if (!form.checkValidity()) {
			return;
		}

		await axios
			.post(`https://api.abrahym.dev/messages`, { ...state })
			.then(response => {
				console.log(response.data);
				form.reset();
			})
			.catch(error => console.log(error));
	};

	return (
		<StyledContact id="contact">
			<div>
				<h3>Get In Touch</h3>
				<form onSubmit={handleSubmit}>
					<div>
						<h4>Contact Information</h4>
						<p>Fill out the form and I will get back to you ASAP</p>
					</div>
					<div className="col">
						<div className="form-group">
							<label htmlFor="name">Name</label>
							<input
								type="text"
								name="name"
								placeholder="Name"
								onChange={handleChange}
								required
							/>
						</div>
						<div className="form-group">
							<label htmlFor="email">Email</label>
							<input
								type="email"
								name="email"
								placeholder="Email"
								onChange={handleChange}
								required
							/>
						</div>
					</div>
					<div className="form-group">
						<label htmlFor="subject">
							Subject{" "}
							<span>
								<em>(optional)</em>
							</span>
						</label>
						<input
							type="text"
							name="subject"
							placeholder="Subject"
							onChange={handleChange}
						/>
					</div>
					<div className="form-group">
						<label htmlFor="message">Message</label>
						<textarea
							name="message"
							id="message-textarea"
							placeholder="Message"
							cols={1}
							rows={5}
							onChange={handleChange}
							required
						/>
					</div>
					<Button type="submit" variant="primary">
						Send a Message!
					</Button>
				</form>
			</div>
		</StyledContact>
	);
};

export default Contact;
