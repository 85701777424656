import { FC } from "react";
import { Button } from "./styles/Button.styled";
import { StyledHome } from "./styles/Home.styled";

const Home: FC<any> = () => {
	return (
		<>
			<StyledHome id="home">
				<div>
					<p className="accented">Hello World! My name is</p>
					<h1>Abrahym Sharf Eldden.</h1>
					<h2>I build web applications.</h2>
					<p className="summary">
						I'm a software developer specializing in web application
						development to give you a
						<span className="accented">
							{" "}
							cleaner web experience
						</span>
						.
					</p>
					<div className="cta-container">
						<Button
							variant="primary"
							onClick={() =>
								window.location.replace("/#contact")
							}>
							Let's Talk
						</Button>
						<Button
							variant="secondary"
							onClick={() =>
								window.location.replace("/#projects")
							}>
							View My Work
						</Button>
					</div>
				</div>
			</StyledHome>
		</>
	);
};

export default Home;
