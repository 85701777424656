import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

    * {
        box-sizing: border-box;
    }
    html {
        scroll-behavior: smooth;
        font-family: ${({ theme }) => theme.fonts.primary}; 
        color: ${({ theme }) => theme.colors.text.primary};
        font-size: 16px;

        &:focus-within {
            animation-name: smoothscroll2;
            scroll-behavior: smooth;
        }
    }

    body {
        margin: 0;
        background: ${({ theme }) => theme.colors.secondary};

    }

    h1,
	h2,h3, h4,
	p {
		margin: 0;
        padding: 0;
        line-height: 1.6;
	}
	h1 {
		font-size: 3.5rem;
	}

	h2 {
		font-size: 2.5rem;
		color: ${({ theme }) => theme.colors.text.primary};
		opacity: 75%;
	}

    h3 {
        color: ${({ theme }) => theme.colors.text.primary};
        position: relative;
        font-size: 2rem;
        margin-block-end: 3rem;

        &::before {
            content: '';
            width: 1.15ch;
            height: 3px;
            background-color: ${({ theme }) => theme.colors.accent};
            border-radius: 50px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    h4 {
        color: ${({ theme }) => theme.colors.text.primary};
        font-size: 1.5rem;
    }
    
    p {
		font-family: ${({ theme }) => theme.fonts.primary};
		color: ${({ theme }) => theme.colors.text.primary};
        font-weight: 300;
        font-size: 1rem;
	}

    a, a:visited, a:active {
        text-decoration: none;
        color: inherit;
    }

    @media screen and (max-width: 650px) {
        h1 {font-size: 1.85rem;}
        h2 { font-size: 1.55rem}
        h3 {font-size: 1.35rem}
        h4 {font-size: 1.20rem;}
        p {font-size: 0.95rem;}
    }

    .accented {
        min-height: 15px;
		color: ${({ theme }) => theme.colors.accent};
    }

    ul {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
        column-gap: 32px;
		align-items: center;
		list-style: none;
		margin: 0;
        padding: 0;
	}

    #root > section {
	    padding-block: clamp(32px, 10vh, 200px);	
        display: grid;
    }

    figure {
        margin: 0;
        position: relative;

        img {
            height: 100%;
            width: 100%;

            object-fit: cover;
            object-position: center;
            transform: scale(0.975); 
            transition: all 0.35s ease-in-out; 
            
            
        }
        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(0.975);
            background: ${({ theme }) => theme.colors.accent};
            opacity: 0.15;
            transition: all 0.35s ease-in-out;


            
        }

        &:hover {

            &::after {
                background: transparent;
                transform: scale(0.95);
            }

            img{
                transform: scale(1)
            }
        }
    }
`;

export default GlobalStyles;
