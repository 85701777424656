import styled from "styled-components";

export const StyledHome = styled.section`
	background: ${({ theme }) => theme.colors.primary};
	height: 100vh;

	place-items: center;
	div {
		width: 60%;
		padding-bottom: 10vh;

		@media screen and (max-width: 1250px) {
			width: 90%;

			p.summary {
				width: 95%;
				font-size: 0.8rem;
			}
		}
	}

	p {
		opacity: 85%;
		font-family: ${({ theme }) => theme.fonts.secondary};
	}
	.accented {
		margin-block: 0;
	}

	.summary {
		width: min(550px, 80%);
	}

	.cta-container {
		margin-top: 32px;
		display: flex;

		@media screen and (max-width: 550px) {
			flex-direction: column;

			button {
				width: 175px;
				margin-block-end: 24px;
			}
		}
	}

	.cta-container > button:nth-child(n + 1) {
		margin-inline-end: 32px;
	}
`;
