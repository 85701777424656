import styled from "styled-components";

export const StyledContact = styled.section`
	background: transparent;

	& > div {
		/* width: min(80%, 700px); */
		width: clamp(350px, 60vw, 700px);
		margin-inline: auto;
	}

	form {
		background-color: ${({ theme }) => theme.colors.primary};
		border-radius: 16px;
		box-shadow: 0 0 16px ${({ theme }) => theme.colors.primary};

		padding: 2rem;
		padding-inline: min(4rem, 32px);

		display: flex;
		flex-direction: column;
		gap: 1rem;

		.col {
			display: flex;
			flex-direction: row;
			gap: min(2rem, 16px);
			flex-wrap: wrap;

			.form-group {
				flex: 1 1 0;
			}
		}

		.form-group {
			display: flex;
			width: 100%;
			flex-direction: column;

			label {
				color: ${({ theme }) => theme.colors.text.primary};
				font-size: 1rem;
				padding-bottom: 8px;

				span em {
					font-size: 0.75rem;
					letter-spacing: 0.5px;
					opacity: 0.65;
				}
			}

			input,
			textarea {
				background-color: ${({ theme }) => theme.colors.primary};
				border: 1px solid ${({ theme }) => theme.colors.text.primary};

				padding: 0.75rem;
				font-size: 1.15rem;
				font-weight: 300;
				border-radius: 8px;
				color: ${({ theme }) => theme.colors.text.primary};
				resize: none;

				box-shadow: inset 0 0 16px -8px ${({ theme }) => theme.colors.secondary};

				transition: all 0.3s ease-in-out;

				&::placeholder {
					font-family: ${({ theme }) => theme.fonts.secondary};
					color: ${({ theme }) => theme.colors.text.primary};
					opacity: 0.35;
					font-size: 1rem;
				}

				&:focus {
					outline: none;
					border: 1px solid ${({ theme }) => theme.colors.accent};
					box-shadow: inset 0 0 32px -16px ${({ theme }) => theme.colors.secondary};
				}
			}
		}

		input {
			accent-color: ${({ theme }) => theme.colors.accent};
		}

		button {
			margin-top: 2rem;
			align-self: flex-start;

			@media screen and (max-width: 800px) {
				width: 100%;
			}
		}
	}
`;
