import styled from "styled-components";

export const StyledAbout = styled.section`
	div {
		max-width: min(90vw, 1200px);
		margin-inline: auto;

		section {
			display: flex;
			gap: 2rem;

			figure {
				flex: 0 1 90%;
				height: auto;
				max-height: 400px;
				aspect-ratio: 3 / 4;
			}
			div {
				flex: 0 1 100%;
				height: auto;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				gap: 2rem;

				p {
					margin-block: 0;
				}
			}

			@media screen and (max-width: 800px) {
				flex-direction: column;
				place-content: center;
				width: 100%;
				figure {
					display: none;
					width: 90%;
					max-width: 500px;
				}

				div {
					width: 90%;
					margin: 0;
					gap: 0;
				}
			}
		}

		section:nth-child(2n + 1) {
			margin-block-start: min(75px, 5rem);
			flex-wrap: wrap;
			flex-direction: row;
			gap: 0;

			h4 {
				flex: 1 1 100%;
			}

			p {
				flex: 1 1 0;
				margin-block: 2rem;
			}

			ul {
				flex: 1 1 0;
				flex-wrap: wrap;
				place-content: flex-start flex-end;
				gap: 0.25rem 0.5rem;
			}
			@media screen and (max-width: 1200px) {
				flex-direction: column;

				ul {
					place-content: flex-start;
				}
			}
		}
	}
`;
